import { useEffect, useMemo, useState } from 'react'
import { useOffering } from './Context'
import { Spinner } from '../Spinner'
import { useAddToOrder, useAvailableTimeslots, useCheckAppointmentEnquiry } from '../OrderHooks'
import { GuestConfig } from './GuestConfig'
import { DurationFilters, TimeSlotPicker } from './TimeSlotPicker'
import { DurationFiltersV2, TimeSlotPickerV2 } from './TimeSlotPickerV2'
import { OfferingModal } from '../OfferingModal'
import { ExclamationIcon } from '@heroicons/react/solid'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { useSiteName } from '../../useSiteName'
import { DateTime } from 'luxon'

const animations = {
  hidden: {
    opacity: 0,
    y: 50,
  },
  show: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: -50,
  },
}

export const CheckAvailabilityButton = ({
  requireMemberGuests = false,
  maxGuests,
  separateNameValuesEnforced = false,
}) => {
  const {
    id,
    offeringType,
    offeringName,
    date,
    guests,
    setGuests,
    isCouples,
    setBasketId,
    enquiriesEnabled,
  } = useOffering()
  const [showModal, setShowModal] = useState(false)
  const [currentStage, setCurrentStage] = useState('timeSelection')
  const canSubmit = useMemo(() => {
    return date !== null
  }, [date])

  // On unmount and when the modal is closed, run `reset()`.
  useEffect(() => reset, [])
  useEffect(() => (!showModal ? reset() : void 0), [showModal])

  const {
    isLoading: isAdding,
    mutateAsync: addToBasketAsync,
    isError,
    error,
    reset: resetMutation,
  } = useAddToOrder(id, offeringType)

  const reset = () => {
    resetMutation()
    setGuestsSetUp(false)
    setCurrentGuestIndex(0)
  }

  const handleDone = (resp) => {
    setShowModal(false)
    window.openSlideover()

    if (window.setBasketGuests !== undefined) {
      window.setBasketGuests(resp.guests)
    }
  }

  const handleAddToOrder = async (arrivalTime, duration) => {
    const payload = {
      selectedDate: date,
      duration: duration,
      time: arrivalTime,
      guests: [guests[currentGuestIndex]],
    }

    // If it's a couples booking, we need to pass all the guests in one item.
    // Otherwise, we create a new basket item for each guest, so we only pass
    // the current guest.
    if (isCouples) {
      payload.guests = guests
    } else {
      payload.guests = [guests[currentGuestIndex]]
    }

    const resp = await addToBasketAsync(payload)
    const lastAddedItem = resp.items[resp.items.length - 1]

    setBasketId(resp.id)

    window.gtag('event', 'add_to_cart', {
      currency: resp.currency.toUpperCase(),
      value: parseFloat((lastAddedItem.total_cost / 100).toFixed(2)),
      items: [
        {
          affiliation: useSiteName(),
          item_id: id,
          item_name: offeringName,
          quantity: 1,
          price: parseFloat((lastAddedItem.total_cost / 100).toFixed(2)),
        },
      ],
    })

    // Are there more guests to do?
    // If it's a couples booking, we've done both guests in one action
    // so now we can trigger `handleDone()`. Otherwise, we need to ask
    // for the time for the next guest (if there is one).
    if (!isCouples && currentGuestIndex < guests.length - 1) {
      setCurrentGuestIndex(currentGuestIndex + 1)
    } else {
      handleDone(resp)
    }
  }

  const handleAddEnquiry = async (timeFrom, timeTo) => {
    const payload = {
      offeringId: id,
      offeringType: 'appointment_enquiry',
      item_configuration: {
        time_from: DateTime.fromMillis(parseInt(timeFrom)).toISO({
          suppressMilliseconds: true,
        }),
        time_to: DateTime.fromMillis(parseInt(timeTo)).toISO({
          suppressMilliseconds: true,
        }),
      },
    }

    // If it's a couples booking, we need to pass all the guests in one item.
    // Otherwise, we create a new basket item for each guest, so we only pass
    // the current guest.
    if (isCouples) {
      payload.guests = guests
    } else {
      payload.guests = [guests[currentGuestIndex]]
    }

    const resp = await addToBasketAsync(payload)
    const lastAddedItem = resp.items[resp.items.length - 1]

    setBasketId(resp.id)

    window.gtag('event', 'add_to_cart', {
      currency: resp.currency.toUpperCase(),
      value: parseFloat((lastAddedItem.total_cost / 100).toFixed(2)),
      items: [
        {
          affiliation: useSiteName(),
          item_id: id,
          item_name: offeringName,
          quantity: 1,
          price: parseFloat((lastAddedItem.total_cost / 100).toFixed(2)),
        },
      ],
    })

    // Are there more guests to do?
    // If it's a couples booking, we've done both guests in one action
    // so now we can trigger `handleDone()`. Otherwise, we need to ask
    // for the time for the next guest (if there is one).
    if (!isCouples && currentGuestIndex < guests.length - 1) {
      setCurrentGuestIndex(currentGuestIndex + 1)
    } else {
      handleDone(resp)
    }
  }

  const [guestsSetUp, setGuestsSetUp] = useState(false)
  const [currentGuestIndex, setCurrentGuestIndex] = useState(0)
  const { t } = useTranslation()

  const handleGuestsSetUp = (guests) => {
    setGuests(guests)
    setGuestsSetUp(true)
  }

  const handleCancel = () => {
    setShowModal(false)

    window.gtag('event', 'pre_add_to_cart_cancel', {
      event_category: 'engagement',
      event_label: offeringName,
    })
  }

  const handleOpen = () => {
    setShowModal(true)

    window.gtag('event', 'pre_add_to_cart_open', {
      event_category: 'engagement',
      event_label: offeringName,
    })
  }

  return (
    <>
      <button
        type="button"
        onClick={handleOpen}
        className={`${
          canSubmit ? 'hover:bg-accent/90' : 'cursor-not-allowed opacity-50'
        } w-full mt-4 items-center px-4 py-2 border border-transparent font-medium rounded-md shadow-sm text-on-accent bg-accent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent`}
        disabled={!canSubmit}
      >
        {t('frontend.check_availability.add_to_order')}
      </button>

      <div className="text-xs text-center text-gray-500 mt-2">
        {t('frontend.check_availability.choose_time_next_stage', {
          count: isCouples ? 1 : guests.length,
        })}
      </div>

      <OfferingModal isOpen={showModal} onClose={handleCancel}>
        {!guestsSetUp && (
          <GuestConfig
            onComplete={handleGuestsSetUp}
            requireMemberGuests={requireMemberGuests}
            maxGuests={maxGuests}
            separateNameValuesEnforced={separateNameValuesEnforced}
          />
        )}

        {guestsSetUp && (
          <>
            {guests.length > 1 && !isCouples && (
              <div className="bg-gray-100 px-6 py-4 text-gray-500">
                {guests[currentGuestIndex].is_lead_booker &&
                  t('frontend.check_availability.currently_selecting_for_yourself')}

                {!guests[currentGuestIndex].is_lead_booker &&
                  t('frontend.check_availability.currently_selecting_for_guest', {
                    guestName: guests[currentGuestIndex].name,
                    interpolation: { escapeValue: false },
                  })}
              </div>
            )}

            {isError && (
              <div className="mx-6 my-4 p-3 mb-0 rounded-md bg-red-100 text-red-700 flex space-x-1">
                <ExclamationIcon className="relative top-1 w-5 h-5" />
                <span>{error?.message ?? t('frontend.check_availability.an_error_occurred')}</span>
              </div>
            )}

            <AnimatePresence>
              {currentStage === 'timeSelection' && (
                <motion.div
                  variants={animations}
                  initial="hidden"
                  animate="show"
                  key={currentGuestIndex}
                  transition={{
                    type: 'spring',
                    stiffness: 260,
                    damping: 20,
                  }}
                >
                  <TimeSelectionStage
                    onSelect={handleAddToOrder}
                    isAdding={isAdding}
                    enquiriesEnabled={enquiriesEnabled}
                    handleMakeEnquiry={() => setCurrentStage('makeEnquiry')}
                    currentGuest={
                      guests.length > 1 && !isCouples ? guests[currentGuestIndex] : null
                    }
                  />
                </motion.div>
              )}
              {currentStage === 'makeEnquiry' && (
                <motion.div
                  variants={animations}
                  initial="hidden"
                  animate="show"
                  transition={{
                    type: 'spring',
                    stiffness: 260,
                    damping: 20,
                  }}
                >
                  <EnquiryStage
                    onSubmit={handleAddEnquiry}
                    goToStage={setCurrentStage}
                    isAdding={isAdding}
                    closeModal={() => setShowModal(false)}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </>
        )}
      </OfferingModal>
    </>
  )
}

export const TimeSelectionStage = ({
  onSelect,
  isAdding,
  currentGuest,
  enquiriesEnabled,
  handleMakeEnquiry,
}) => {
  const { offeringType, durations = [], basketId } = useOffering()
  const { t } = useTranslation()
  const [selectedDuration, setSelectedDuration] = useState(durations[0] ?? [])
  const { isLoading, data: { data: times = [] } = {} } = useAvailableTimeslots(
    selectedDuration,
    basketId,
    {
      refetchOnWindowFocus: false,
    }
  )

  const showEnquireButton = enquiriesEnabled && times.find((slot) => slot.quantity_available === 0)

  return (
    <div className="p-6">
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-xl font-medium truncate">
          {!!currentGuest?.name &&
            t('frontend.check_availability.now_choose_time_for_guest', {
              guestName: currentGuest.name,
              interpolation: { escapeValue: false },
            })}
          {!currentGuest?.name && t('frontend.check_availability.now_choose_time')}
        </h1>

        {offeringType === 'area_booking' && durations.length > 0 && (
          <>
            {window.featureFlags.includes('package_config_tweaks') && (
              <DurationFiltersV2
                durations={durations}
                selectedDuration={selectedDuration}
                onDurationSelect={setSelectedDuration}
              />
            )}

            {!window.featureFlags.includes('package_config_tweaks') && (
              <DurationFilters
                durations={durations}
                selectedDuration={selectedDuration}
                onDurationSelect={setSelectedDuration}
              />
            )}
          </>
        )}
      </div>

      {showEnquireButton && (
        <div className="mb-6">
          <button
            type="button"
            onClick={handleMakeEnquiry}
            className={`w-full items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent hover:bg-gray-50`}
          >
            {t('frontend.check_availability.make_an_enquiry')}
          </button>
          <div className="text-xs text-center text-gray-500 mt-2">
            {t('frontend.check_availability.make_an_enquiry_description')}
          </div>
        </div>
      )}

      {window.featureFlags.includes('package_config_tweaks') && (
        <TimeSlotPickerV2
          slots={times}
          isLoading={isLoading}
          onSelect={(slot) => onSelect(slot.start_time, slot.duration)}
          disabled={isAdding || isLoading}
          showPrice
          showCapacity={offeringType === 'session'}
        />
      )}

      {!window.featureFlags.includes('package_config_tweaks') && (
        <TimeSlotPicker
          slots={times}
          isLoading={isLoading}
          onSelect={(slot) => onSelect(slot.start_time, slot.duration)}
          disabled={isAdding || isLoading}
          showPrice
          showCapacity={offeringType === 'session'}
        />
      )}
    </div>
  )
}

const EnquiryStage = ({ onSubmit, isAdding, goToStage, closeModal }) => {
  const { t } = useTranslation()
  const { date } = useOffering()
  const [timeFrom, setTimeFrom] = useState('')
  const [timeTo, setTimeTo] = useState('')
  const [dateFrom, setDateFrom] = useState(date)
  const [dateTo, setDateTo] = useState(date)
  const [checkAvailabilityEnabled, setCheckAvailabilityEnabled] = useState(false)

  const {
    data: { data: checkResult } = {},
    isLoading: isChecking,
    isSuccess: checkEnquiryAvailabilitySuccess,
  } = useCheckAppointmentEnquiry(dateFrom, dateTo, {
    enabled: checkAvailabilityEnabled,
  })

  // TODO: can we use site times here?
  const firstTimeOption = date.set({ hour: 7, minute: 0 })
  const lastTimeOption = date.set({ hour: 22, minute: 0 })
  let currentOptionTime = firstTimeOption

  const timeOptions = [
    {
      value: '',
      label: 'Select',
    },
  ]

  while (currentOptionTime <= lastTimeOption) {
    timeOptions.push({
      value: currentOptionTime,
      label: currentOptionTime.toLocaleString(DateTime.TIME_SIMPLE),
    })

    currentOptionTime = currentOptionTime.plus({ minutes: 30 })
  }

  const handleSubmit = () => {
    onSubmit(timeFrom, timeTo)
  }

  const handleGoToTimeSelectionStage = () => {
    goToStage('timeSelection')
  }

  const handleCheckEnquiryAvailability = () => {
    setDateFrom(DateTime.fromMillis(parseInt(timeFrom)))
    setDateTo(DateTime.fromMillis(parseInt(timeTo)))
    setCheckAvailabilityEnabled(true)
  }

  return (
    <div className="p-6">
      <h1 className="text-xl font-medium mb-4">
        {t('frontend.check_availability.make_an_enquiry')}
      </h1>
      {checkAvailabilityEnabled && checkEnquiryAvailabilitySuccess && checkResult.can_enquire && (
        <SubmitEnquiry onSubmit={handleSubmit} isAdding={isAdding} />
      )}
      {checkAvailabilityEnabled && checkEnquiryAvailabilitySuccess && !checkResult.can_enquire && (
        <CannotEnquire
          result={checkResult}
          goToTimeSelection={handleGoToTimeSelectionStage}
          goToEnquiryTimes={() => setCheckAvailabilityEnabled(false)}
          closeModal={closeModal}
        />
      )}
      {!(checkEnquiryAvailabilitySuccess && checkAvailabilityEnabled) && (
        <>
          <div className="flex flex-col gap-y-4">
            <p className="text-sm">{t('frontend.check_availability.enquiry_explanation')}</p>
            <p className="text-sm">{t('frontend.check_availability.enquiry_what_times')}</p>
            <div className="mt-2 lg:flex lg:gap-x-2">
              <div className="w-full">
                <div className="text-sm font-medium">
                  {t('frontend.check_availability.enquiry_time_from')}
                </div>
                <select
                  className="mt-2 border border-gray-200 rounded-lg text-sm p-3 w-full focus:outline-none focus:ring-1 focus:ring-accent focus:border-accent"
                  onChange={(e) => setTimeFrom(e.target.value)}
                  value={timeFrom}
                >
                  {timeOptions.map((time) => (
                    <option key={time.value} value={time.value}>
                      {time.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-full">
                <div className="text-sm font-medium">
                  {t('frontend.check_availability.enquiry_time_to')}
                </div>
                <select
                  className="mt-2 border border-gray-200 rounded-lg text-sm p-3 w-full focus:outline-none focus:ring-1 focus:ring-accent focus:border-accent"
                  onChange={(e) => setTimeTo(e.target.value)}
                  value={timeTo}
                >
                  {timeOptions.map((time) => (
                    <option key={time.value} value={time.value}>
                      {time.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mt-4 lg:flex lg:gap-x-2 lg:flex-row-reverse">
              <button
                className="relative bg-accent overflow-hidden w-full text-sm text-on-accent font-medium p-3 rounded-md"
                onClick={handleCheckEnquiryAvailability}
                disabled={isChecking}
              >
                {t('frontend.check_availability.continue')}
                {isChecking && (
                  <div className="absolute inset-0 bg-accent flex justify-center items-center">
                    <Spinner width="m-5" height="h-5" />
                  </div>
                )}
              </button>
              <button
                className="w-full flex items-center justify-center text-sm text-accent border border-accent font-medium p-2 rounded-md space-x-1 hover:bg-accent/10"
                onClick={handleGoToTimeSelectionStage}
              >
                Back
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const SubmitEnquiry = ({ onSubmit, isAdding }) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-y-4">
      <p className="text-sm">{t('frontend.check_availability.enquiry_can_be_accepted')}</p>
      <p className="text-sm">{t('frontend.check_availability.enquiry_card_details_explanation')}</p>
      <button
        className="relative bg-accent overflow-hidden w-full text-sm text-on-accent font-medium p-3 rounded-md"
        onClick={onSubmit}
        disabled={isAdding}
      >
        {t('frontend.check_availability.add_enquiry')}
        {isAdding && (
          <div className="absolute inset-0 bg-accent flex justify-center items-center">
            <Spinner width="m-5" height="h-5" />
          </div>
        )}
      </button>
    </div>
  )
}

const CannotEnquire = ({ result, goToTimeSelection, goToEnquiryTimes, closeModal }) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-y-4">
      {result.cannot_enquire_reason === 'bookable_slots_found' && (
        <>
          <p className="text-sm">
            {t('frontend.check_availability.cannot_enquire_bookable_slots_found')}
          </p>
          <button
            className="mt-2 relative bg-accent overflow-hidden w-full text-sm text-on-accent font-medium p-3 rounded-md"
            onClick={goToTimeSelection}
          >
            {t('frontend.check_availability.choose_time')}
          </button>
        </>
      )}
      {result.cannot_enquire_reason !== 'bookable_slots_found' && (
        <>
          <p className="text-sm">{t('frontend.check_availability.cannot_enquire_general')}</p>
          <div className="mt-2 lg:flex lg:gap-x-2 lg:flex-row-reverse">
            <button
              className="relative bg-accent overflow-hidden w-full text-sm text-on-accent font-medium p-3 rounded-md"
              onClick={goToEnquiryTimes}
            >
              {t('frontend.check_availability.enquiry_change_times')}
            </button>
            <button
              className="w-full flex items-center justify-center text-sm text-accent border border-accent font-medium p-2 rounded-md space-x-1 hover:bg-accent/10"
              onClick={closeModal}
            >
              {t('frontend.check_availability.enquiry_change_date')}
            </button>
          </div>
        </>
      )}
    </div>
  )
}
